import classNames from 'classnames'
import React from 'react'

export default function LanguageOverrideModal({
    no_of_conficts,
    file_key,
    onConfirm,
    onDeny,
    is_active,
    loading
}) {
    return (
        <div className={classNames('modal', { 'is-active': is_active })}>
            <div className="modal-background">

            </div>
            <div className="modal-card">
                <section className="modal-card-body">
                    {
                        loading ?
                            <progress className="progress is-large is-info" max="100">60%</progress>
                            :
                            <>
                                <p className="lang-conflict-modal-title">
                                    Preferred lanugage conflict
                                </p>
                                
                                <div className="lang-conflict-modal-body-text mg-top-15 mg-bottom-15">
                                    <span>
                                        {no_of_conficts} employee(s) have selected a different preferred language to chat with Amber. Are you sure you want to override their current preferred language(s)?
                                    </span>
                                </div>
                                <div className="has-text-right mg-top-50 mg-right-5">
                                    <a href={file_key} className="button is-outlined is-info is-pulled-left">Download List</a>
                                    <span className="button is-outlined is-info mg-right-10" onClick={onConfirm}>Yes</span>
                                    <span onClick={onDeny} className="button is-info">
                                        No
                                    </span>
                                </div>
                            </>
                    }
                </section>
            </div>
        </div>
    )
}
