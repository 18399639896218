import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProgressLoader from '../loader/ProgressLoader';
import parse from 'html-react-parser';
import valid_img from '../../../static/images/valid.svg';

const DataUploadModal = ({
    is_active,
    upload_type,
    onClose,
    uploadCSV,
    openFileSelector,
    loading,
    csv_cleaned,
    error_msg,
    resetUploadVariables,
    file_link,
    change_log,
    FinaluploadUserCSV,
    empty_column_warning,
    partial_columns_warning,
    personal_email_warnings,
    clean_file_download_text
  }) => {
return (
    <div className={classnames('modal', {"is-active" : is_active})}>
        <div onClick={onClose} className="modal-background"></div>
        <div className="modal-content card border-5">
            <div className="card-content">
                {loading 
                ? <div className="db-uploading">
                    <p className="is-size-6 padding-5 no-padding-left has-text-centered">Uploading...</p>
                    <ProgressLoader/>
                </div>
                :   
                    <div>
                        {csv_cleaned 
                        ? 
                        <div>
                            {error_msg 
                                ? 
                                <div>
                                    <p className="is-size-7">The uploaded file has some errors, you can download the clean file for more details.</p>
                                    <p className="is-size-7 has-text-danger has-text-weight-bold mg-top-10">{parse(error_msg)}</p>
                                    <p className="is-size-7 sub-text mg-top-10 buttons justify-flex-end">
                                        <a href={file_link} target="_blank" className="button is-danger is-small radius-5" rel="noopener noreferrer">{clean_file_download_text}</a>
                                        <button onClick={resetUploadVariables.bind(this, false)} className="button is-link is-small is-outlined radius-5">Upload Again</button>
                                    </p>
                                </div>
                                : 
                                <div>
                                    <div className="has-text-centered"><img src={valid_img} alt="valid" width="60px"/></div>
                                    <p className="font-15 mg-top-10">The uploaded file is valid and the following changes will be made:</p>
                                    <p className="is-size-7 mg-top-10 has-text-weight-bold"><b>Adding:</b> <span className="is-size-6 has-text-link"> {change_log.number_of_creation}</span> new user(s)</p>
                                    <p className="is-size-7 mg-top-10 has-text-weight-bold"><b>Updating:</b> <span className="is-size-6 has-text-link"> {change_log.number_of_updation}</span> user(s)</p>
                                    <p className="is-size-7 mg-top-10 has-text-weight-bold"><b>Disabling:</b> <span className="is-size-6 has-text-link"> {change_log.number_of_disabled}</span> user(s)</p>
                                    <p className="is-size-7 has-text-danger mg-top-10 has-text-weight-bold">Note: Nobody (including the inFeedo team) will be able to reverse this.</p>
                                    <br/>
                                    {empty_column_warning.length || partial_columns_warning.length || personal_email_warnings.length 
                                    ? <article className="message is-warning">
                                        <div className="message-body content">
                                            <p className="has-text-grey-dark"><b>Warnings</b></p>
                                            <ul>
                                                {empty_column_warning.map((warning, index) => (
                                                    <li key={index}>{warning}</li>
                                                ))}
                                                {partial_columns_warning.map((warning, index) => (
                                                    <li key={index}>{warning}</li>
                                                ))}
                                                {personal_email_warnings ? <li>{personal_email_warnings} user(s) with personal email in official email column.</li> : null}
                                            </ul>
                                        </div>
                                    </article> : null}
                                    <p className="is-size-7 sub-text mg-top-10 buttons justify-flex-end">
                                        <button onClick={resetUploadVariables.bind(this, false)} className="button is-link is-small is-outlined radius-5">Go Back</button>
                                        <button onClick={FinaluploadUserCSV} className="button is-link is-small radius-5">Confirm Upload</button>
                                    </p>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            {/* {upload_type === 2 ? <h1 className="login-heading no-mg-top">Master Upload</h1> : null}
                            {upload_type === 1 ? <h1 className="login-heading no-mg-top">Add / Update / Disable Employees</h1> : null}
                            {upload_type === 2 ? <p className="is-size-6 has-text-black mg-bottom-20">Replace the existing data: arrange data in the right format, upload it here in batches of 12,000 each.</p> : null}
                            {upload_type === 1 ? <p className="is-size-6 has-text-black mg-bottom-20">Update the existing data: arrange data in the right format, upload it here in batches of 12,000 each.</p> : null}
                            <p className="is-size-7 has-text-black has-text-weight-bold">Select Unique Identifier:</p>
                            <br/>
                            <div className="columns">
                                <div onClick={openFileSelector.bind(this, 1)} className="column is-paddingless is-6">
                                    <div className="db-button has-text-centered">
                                        <h3 className="has-text-weight-bold mg-bottom-5">
                                            Email ID
                                        </h3>
                                        <p><small>All the users will be mapped by their email IDs only.</small></p>
                                    </div>
                                </div>
                                <div onClick={openFileSelector.bind(this, 2)} className="column is-paddingless is-6">
                                    <div className="db-button has-text-centered">
                                        <h3 className="has-text-weight-bold mg-bottom-5">
                                            Employee Code
                                        </h3>
                                        <p><small>All the users will be mapped by their Employee Code only.</small></p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        
                        }
                    </div>
                }
            </div> 
            <button onClick={onClose} className="modal-close is-large" aria-label="close"></button>
        </div>
        {!loading && !error_msg ? <input type="file" id='uploadUsers' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={uploadCSV} hidden/> : null}
    </div>
);
};

// Porptypes
// ---------
DataUploadModal.propTypes = {
    is_active: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    uploadCSV: PropTypes.func.isRequired,
    upload_type: PropTypes.number.isRequired,
    openFileSelector: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    csv_cleaned: PropTypes.bool.isRequired,
    error_msg: PropTypes.string.isRequired,
    resetUploadVariables: PropTypes.func.isRequired,
    file_link: PropTypes.string.isRequired,
    change_log: PropTypes.object.isRequired,
    FinaluploadUserCSV: PropTypes.func.isRequired,
    empty_column_warning: PropTypes.array.isRequired,
    partial_columns_warning: PropTypes.array.isRequired,
    personal_email_warnings: PropTypes.number.isRequired
};

// default props
// -------------
DataUploadModal.defaultProps = {
    is_active: false
};

export default DataUploadModal;
